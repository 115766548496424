.skeleton {
	background-color: rgba(39, 39, 39, 0.1);
	border-radius: 4px;
	margin-bottom: 12px;
	animation: pulse 1.5s infinite;
}

@keyframes pulse {
	0% {
		background-color: rgba(39, 39, 39, 0.4);
	}
	50% {
		background-color: rgba(39, 39, 39, 0.1);
	}
	100% {
		background-color: rgba(39, 39, 39, 0.4);
	}
}

.poster {
	width: 100%;
	height: 455px; /* Высота для постера */
}

.title {
	width: 70%;
	height: 30px;
}

.tagline {
	width: 50%;
	height: 20px;
}

.overview {
	width: 100%;
	height: 60px;
}

.genres {
	width: 80%;
	height: 25px;
}

.cast {
	width: 90%;
	height: 25px;
}

.favbutton {
	width: 100%;
	height: 48px;
}

.scelet-player-container {
	width: 100%;
	height: 500px;
}
