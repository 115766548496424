:root {
	--bs-body-bg: #101010;
	--border-radius: 6px;
}

html {
	font-size: 18px;
}

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	overflow-y: scroll;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

a {
	text-decoration: none;
}

.btn {
	padding: 8px 16px;
	font-size: 16px;
	font-weight: 500;
	min-height: 44px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: var(--border-radius);
}

.form-control {
	border-radius: var(--border-radius);
	background-color: rgba(255, 255, 255, 0.02);
	border-color: rgba(255, 255, 255, 0.08);
	height: 44px;
}

.form-control:focus {
	border-color: #fff;
	outline: none;
	box-shadow: none;
}

.form-control[placeholder] {
	font-size: 16px;
}

@media (max-width: 991px) {
	.container {
		min-width: 100%;
		margin: 0;
	}
}

.header .btn.btn-secondary {
	background-color: transparent;
}

.header .btn.btn-secondary:active,
.header .btn.btn-secondary:focus,
.header .btn.btn-secondary:hover {
	background-color: rgba(255, 255, 255, 0.03);
	color: #fff;
}

.header .btn.btn-secondary.active {
	background-color: #fff;
	color: #191919 !important;
}
.btn.btn-secondary {
	border-radius: var(--border-radius);
	background-color: rgba(255, 255, 255, 0.04);
	border: 0;
}

.btn.btn-secondary:active,
.btn.btn-secondary:focus,
.btn.btn-secondary:hover {
	background-color: rgba(255, 255, 255, 0.1);
}

.card-movie {
	min-width: 80px;
	text-decoration: none;
}

.card-image {
	min-width: 80px;
	max-height: 348px;
	background-color: #191919;
	border-radius: var(--border-radius);
	overflow: hidden;
	transition: all 0.3s;
	outline: 3px solid transparent;
}

.card-image:hover {
	outline: 3px solid #fff;
}

.card-img-top {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.card-movie .card-title {
	font-size: 18px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.movie-details li {
	display: flex;
	padding: 8px 0;
}

.movie-details li ul li {
	padding: 0;
}

.movie-details li span:first-child {
	font-weight: medium;
	min-width: 100px;
	opacity: 0.72;
}

.dropdown-search {
	margin-top: 8px;
}

.movie-badge {
	position: absolute;
	top: 10px;
	right: 10px;
}

.movie-actor-photo {
	width: 56px;
	height: 56px;
	object-fit: cover;
	border-radius: var(--bs-border-radius);
}

.badge-genre {
	padding: 8px 12px;
	line-height: 1;
	border-radius: var(--border-radius);
	background-color: rgba(255, 255, 255, 0.04);
	color: #fff;
}

.badge-genre:hover {
	background-color: #fff;
	color: #191919;
}

.react-select__control {
	background-color: #191919 !important;
	border: 0 !important;
	min-height: 40px !important;
	border-radius: 8px !important;
}

.react-select__multi-value {
	background-color: #191919 !important;
	color: #fff !important;
}

.react-select__multi-value__label {
	color: #fff !important;
}

.react-select__option {
	color: #fafafa !important;
	background-color: #191919 !important;
}

.react-select__indicator-separator {
	background-color: #3c3c3c !important;
}

/* .cover-movie-page {
	left: 0;
	position: absolute;
	z-index: -2;
	width: 100%;
	height: 500px;
	filter: blur(100px);
	opacity: 0.1;
} */

.backrop-image {
	position: absolute;
	top: 0px;
	left: -100px;
	width: 400px;
	height: 400px;
	border-radius: 50%;
	z-index: -2;
	opacity: 0.8;
	filter: blur(150px);
}

.backrop-image .img-fluid {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.kinobox_player {
	overflow: hidden;
	border-radius: var(--bs-border-radius);
	height: 550px;
	width: 100%;
}

@media (max-width: 768px) {
	.kinobox_player {
		height: 350px;
	}
}

.bg-dark {
	background: rgba(255, 255, 255, 0.04) !important;
}

.actor-name,
.director-name {
	position: relative;
	cursor: pointer;
}

.movie-actor-photo,
.movie-director-photo {
	position: relative;
	cursor: pointer;
}

.tooltip-box {
	display: none;
	position: absolute;
	background: rgba(0, 0, 0, 0.9) !important;
	color: #fff;
	padding: 5px;
	border-radius: 6px;
	top: 8px;
	left: 0;
	white-space: nowrap;
	z-index: 1;
}

.tool-name:hover .tooltip-box,
.tool-name:hover .tooltip-box {
	display: block;
}

.episode-image {
	object-fit: cover;
}

.nav-mobile {
	z-index: 9999;
}

@media (max-width: 768px) {
	.nav-mobile {
		position: fixed;
		left: 1%;
		bottom: 1%;
		background-color: #191919;
		width: 98%;
		padding: 4px;
		border-radius: 100px;
		justify-content: space-between;
	}

	.nav-mobile .btn {
		border-radius: 100px;
		flex-basis: 100%;
	}
}
